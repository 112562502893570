import React, { useCallback, useEffect } from 'react';
import styles from './ArticleNews.module.css';
import Blogger from '../../api/blogger';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

export default function Article() {
  const { newsId } = useParams();
  const { data: postData } = useQuery(['news'], () => new Blogger().get(), {
    staleTime: 1000 * 60 * 10,
  });
  const getImgSrcList = useCallback((htmlString) => {
    const rootElement = document.createElement('div');
    document.body.appendChild(rootElement);
    rootElement.innerHTML = htmlString;

    const imgTags = rootElement.querySelectorAll('img');
    const imgSrcList = Array.from(imgTags).map((imgTag) => imgTag.src);

    rootElement.remove();

    return imgSrcList;
  }, []);

  const getTextElementString = useCallback((htmlString) => {
    const rootElement = document.createElement('div');
    document.body.appendChild(rootElement);
    rootElement.innerHTML = htmlString;

    let tags = rootElement.querySelectorAll('img');
    tags.forEach((tag) => tag.remove());

    tags = rootElement.querySelectorAll('br');
    tags.forEach((tag) => tag.remove());

    tags = rootElement.querySelectorAll('iframe');
    tags.forEach((tag) => tag.remove());

    const textElementString = rootElement.innerHTML;

    rootElement.remove();

    return textElementString;
  }, []);

  const getIFrameSrc = useCallback((htmlString) => {
    const rootElement = document.createElement('div');
    document.body.appendChild(rootElement);
    rootElement.innerHTML = htmlString;

    const iFrame = rootElement.querySelector('iframe');

    rootElement.remove();

    return iFrame ? iFrame.src : undefined;
  }, []);

  const { title, content } = postData.items[newsId];
  const imgSrcList = getImgSrcList(content);
  const textElementString = getTextElementString(content);
  const iFrameSrc = getIFrameSrc(content);

  useEffect(() => {
    document.getElementById('root').scrollIntoView();
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles['space-container']} />

      <div className={styles['text-container']}>{parse(textElementString)}</div>
      <div className={styles['image-container']}>
        {imgSrcList.map((imgSrc, index) => (
          <img
            className={styles.image}
            key={index}
            src={imgSrc}
            alt={`img${index}`}
          ></img>
        ))}
      </div>
      {iFrameSrc && (
        <iframe
          className={styles.iframe}
          title={title}
          src={iFrameSrc}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </section>
  );
}
