import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Worship from './pages/Worship/Worship';
import Pastor from './pages/introduce/Pastor/Pastor';
import Greetings from './pages/introduce/Greetings/Greetings';
import Location from './pages/Location/Location';
import Home from './pages/Home/Home';
import NotFound from './pages/NotFound';
import Purpose from './pages/Purpose/Purpose';
import Vision from './pages/Vision/Vision';
import ArticleNews from './pages/ArticleNews/ArticleNews';
import News from './pages/News/News';
import NoticeDialog from './components/NoticeDialog/NoticeDialog';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { YoutubeApiProvider } from './context/YoutubeApiContext';

const router = createHashRouter(
  [
    {
      path: '/',
      element: <App />,
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          element: (
            <YoutubeApiProvider>
              <NoticeDialog />
              <Home />
            </YoutubeApiProvider>
          ),
        },
        { path: '/introduce/pastor', element: <Pastor /> },
        { path: '/introduce/greetings', element: <Greetings /> },
        { path: '/calling/purpose', element: <Purpose /> },
        { path: '/calling/vision', element: <Vision /> },
        { path: '/worship', element: <Worship /> },
        { path: '/location', element: <Location /> },
        { path: '/news', element: <News /> },
        { path: '/news/:newsId', element: <ArticleNews /> },
      ],
    },
  ],
  { basename: '' }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
