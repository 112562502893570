import mockYoutubeData from './mockYoutubeData.json';

export default class Youtube {
  get() {
    return mockYoutubeData.items.map((item) => ({
      ...item,
      id: item.id.videoId,
    }));
  }
}
