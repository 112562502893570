import React, { useCallback } from 'react';
import styles from './ImagePost.module.css';
import { useNavigate } from 'react-router-dom';

export default function ImagePost({ post: { title, date, thumbnail }, index }) {
  const navigate = useNavigate();
  const handleClickItem = useCallback(
    (e) => {
      navigate(`/news/${index}`);
    },
    [index, navigate]
  );

  return (
    <div className={styles['container']} onClick={handleClickItem}>
      <img
        className={styles['preview-image']}
        src={thumbnail}
        alt={`news${index}`}
      />
      <div className={styles['text-container']}>
        <h1 className={styles.title}>{title}</h1>
        <h3
          className={styles.date}
        >{`${date[0]}년 ${date[1]}월 ${date[2]}일 `}</h3>
      </div>
    </div>
  );
}
