import React from 'react';
import styles from './News.module.css';
import Blogger from '../../api/blogger';
import { useQuery } from '@tanstack/react-query';
import Background from '../../components/Background/Background';
import ImagePost from '../../components/ImagePost/ImagePost';

export default function News() {
  const { data: postData } = useQuery(['news'], () => new Blogger().get(), {
    staleTime: 1000 * 60 * 10,
  });

  return (
    <section>
      <Background
        title={'새누리 소식'}
        subtitle={'Good News'}
        imageUrl={'/img/sharing.png'}
      />

      <ul className={styles['post-container']}>
        {postData &&
          postData.items.map((post, index) => (
            <li key={index}>
              <ImagePost post={post} index={index} />
            </li>
          ))}
      </ul>

      <div className={styles['post-button-container']}>
        <button className={styles['post-button']}>
          <a
            className={styles['post-link']}
            href="https://www.instagram.com/saenuree_berlin/"
            target="blank"
          >
            새로운 소식 보기
          </a>
        </button>
      </div>
    </section>
  );
}
