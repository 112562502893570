import React from 'react';
import styles from './Home.module.css';
import YoutubeVideo from '../../components/YoutubeVideo/YoutubeVideo';
import MainScreen from '../../components/MainScreen/MainScreen';
import Verse from '../../components/Verse/Verse';
import { useQuery } from '@tanstack/react-query';
import { useYoutubeApi } from '../../context/YoutubeApiContext';

export default function Home() {
  const { youtube } = useYoutubeApi();
  const { data: videos } = useQuery(['videos'], () => youtube.get(), {
    staleTime: 1000 * 60 * 10,
  });

  return (
    <div className={styles.container}>
      <MainScreen />
      <div className={styles.padding} />

      <Verse />

      <section className={styles['video-container']}>
        {videos && (
          <ul className={styles.videos}>
            {videos.map((frameData, index) => (
              <li key={index}>
                <YoutubeVideo frameData={frameData} />
              </li>
            ))}
          </ul>
        )}

        <div className={styles['youtube-container']}>
          <button className={styles['youtube-button']}>
            <a
              className={styles['youtube-link']}
              href="https://www.youtube.com/@berlinsaenuree/videos"
              target="blank"
            >
              더 많은 영상 보기
            </a>
          </button>
        </div>
      </section>
    </div>
  );
}
