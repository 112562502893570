import React, { useCallback, useState } from 'react';
import styles from './NoticeDialog.module.css';
import noticeImg from './img/background.png';
import { useQuery } from '@tanstack/react-query';

export default function NoticeDialog() {
  const [style, setStyle] = useState({
    opacity: 1,
  });
  const [vanished, setVanished] = useState(false);
  const handleShow = useCallback(() => {
    setStyle({ opacity: 0 });
    setTimeout(function () {
      setVanished(true);
    }, 600);
  }, []);

  const { isLoading, data: noticeData } = useQuery(
    ['noticeData'],
    async () =>
      fetch('data/notice.json')
        .then((res) => res.json())
        .catch(() => {
          console.error('Check the notice data');
          return { date: null, contents: null };
        }),
    { staleTime: 1000 * 60 * 10 }
  );

  if (
    isLoading ||
    typeof noticeData.date !== 'string' ||
    typeof noticeData.contents !== 'string' ||
    vanished
  ) {
    return <></>;
  }

  return (
    <div
      className={styles.container}
      style={style}
      onClick={handleShow}
      onWheel={handleShow}
      onTouchMove={handleShow}
    >
      <img className={styles['notice-img']} src={noticeImg} alt="notice" />
      <div className={styles['date-background']}>
        <h3 className={styles.date}>{noticeData.date}</h3>
      </div>
      <div className={styles.contents}>{noticeData.contents}</div>
      <div className={styles.foot} />
    </div>
  );
}
