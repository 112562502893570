import React from 'react';
import styles from './Verse.module.css';
import { useQuery } from '@tanstack/react-query';

export default function Verse() {
  const windowSize = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--size-md')
  );

  const { isLoading, data: verseData } = useQuery(
    ['verseData'],
    async () =>
      fetch('data/verse.json')
        .then((res) => res.json())
        .catch(() => {
          console.error('Check the verse data');
          return { title: defaultTitle, verse: defaultVerse };
        }),
    { staleTime: 1000 * 60 * 10 }
  );

  return (
    <section className={styles.container}>
      {isLoading || typeof verseData.title !== 'string' ? (
        defaultTitle
      ) : window.innerWidth < windowSize ? (
        verseData.title.split('\n').map((text, index) => (
          <h1 className={styles.title} key={index}>
            {text}
          </h1>
        ))
      ) : (
        <h1 className={styles.title}>{verseData.title}</h1>
      )}

      <div className={styles.divider}></div>

      {isLoading || typeof verseData.verse !== 'string' ? (
        <p className={styles.verse}>{defaultVerse}</p>
      ) : (
        verseData.verse.split('\n').map((text, index) => (
          <p className={styles.verse} key={index}>
            {text}
          </p>
        ))
      )}
    </section>
  );
}

const defaultTitle = '새로운 일을 행하실\n하나님을 기대하라';
const defaultVerse =
  '그런즉 누구든지 그리스도 안에 있으면 새로운 피조물이라 이전 것은 지나갔으니 보라 새 것이 되었도다 (고후5:17절)';
