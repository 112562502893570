import mockBloggerNewsData from './mockBloggerNewsData.json';

export default class Blogger {
  get() {
    return {
      items: mockBloggerNewsData.map((item) => {
        return {
          title: item.title,
          content: item.content,
          date: item.published.slice(0, item.published.indexOf('T')).split('-'),
          thumbnail: item.thumbnail,
        };
      }),
    };
  }
}
