import React, { useEffect, useState } from 'react';
import styles from './InformationCard.module.css';

export default function InformationCard({ title, time, timeDetail, place }) {
  const [style, setStyle] = useState({
    opacity: 0,
    transform: `translateY(100px)`,
  });

  useEffect(() => {
    setTimeout(function () {
      setStyle({ opacity: 1 });
    }, 300);
  }, []);

  return (
    <article className={styles.container} style={style}>
      <div className={styles.title}>
        <h3>{title}</h3>
      </div>
      <div className={styles.information}>
        <p>{time}</p>
        {timeDetail ? <p>{timeDetail}</p> : <></>}
        <p>{place}</p>
      </div>
    </article>
  );
}
